<template>
  <v-card class="elevation-0">

    <v-card-title>
      {{ title }}
      <v-spacer></v-spacer>

        <v-text-field
            v-on:keyup.enter="searchOnEnter"
            v-on:blur="searchOnEnter"
            append-icon="mdi-magnify"
            hint="Kode, Deskripsi (press ENTER to search)"
            label="Search"
        ></v-text-field>

    </v-card-title>
    <v-data-table
        v-model="selectedItems"
        :single-select="!multiSelect"
        :show-select="multiSelect"
        :headers="headers"
        :items="fMaterialGroup3sFiltered"
        :page.sync="currentPage"
        :items-per-page="pageSize"
        hide-default-footer
        class="elevation-0"
        @page-count="totalTablePages =totalPaginationPages "
    >
      <template v-slot:top  v-if="false">
        <v-row align="center" class="ml-4 mr-4">
          <v-switch
              v-model="multiSelect"
              :label="multiSelect?'Multi Select':'Single Select'"
              x-small
              class="pa-3"
          ></v-switch>
          <v-btn
              fab
              dark
              color="red accent-4"
              x-small
              :disabled="!multiSelect"
              class="mr-2"
              @click="deleteDialogMultiShow"
          >
            <v-icon>mdi-delete</v-icon>
          </v-btn>

          <v-spacer></v-spacer>

          <v-btn
              fab
              dark
              color="green"
              x-small
              @click="showDialogNew"
              :disabled="multiSelect"
          >
            <v-icon>mdi-plus</v-icon>
          </v-btn>
        </v-row>
      </template>


      <template v-slot:[`item.statusActive`]="{ item }">
        <v-chip
            :color="getColorStatusActive(item.statusActive)"
            dark
            x-small
        >
          {{ item.statusActive==true?'Aktif': 'Non-Aktif' }}
        </v-chip>
      </template>


      <template v-slot:[`item.avatar`]="{ item}">
          <v-img
              :lazy-src="lookupImageUrlLazy(item)"
              :src="lookupImageUrl(item)"
              alt="avatar"
              width="70px"
              height="70px"
              class="ma-2 rounded"
          >
          </v-img>
      </template>

      <template v-slot:[`item.description`]="{ item }">
        <div class="subtitle-1">
          {{item.description}}
        </div>
        <div class="subtitle-2 font-weight-light" v-if="item.kode1">
          *{{item.kode1}}
        </div>
      </template>

      <template v-slot:[`item.fmaterialGroup2Bean`]="{ item }">
        <div class="fill-height">
          <div>
            {{ lookupFMaterialGroup2(item.fmaterialGroup2Bean) }}
          </div>
          <div>
            <v-chip
                :color="getColorStatusActive(item.statusActive)"
                dark
                x-small
            >
              {{ item.statusActive==true?'Aktif': 'Non-Aktif' }}
            </v-chip>
          </div>
        </div>
      </template>

      <template v-slot:[`item.actions`]="{ item }">
        <v-btn
            @click="showDialogEdit(item)"
            icon
            :disabled="multiSelect"
        >
          <v-icon
              small
              color="warning"
          >
            mdi-pencil
          </v-icon>
        </v-btn>

<!--        <v-btn-->
<!--            @click="deleteDialogShow(item)"-->
<!--            icon-->
<!--            :disabled="multiSelect"-->
<!--        >-->
<!--          <v-icon-->
<!--              small-->
<!--              color="red accent-4"-->
<!--          >-->
<!--            mdi-delete-->
<!--          </v-icon>-->
<!--        </v-btn>-->
      </template>

    </v-data-table>
<!--    @page-count="pageCount = $event"-->

    <v-container>
      <v-row justify="end" align="center">
        <v-col
        cols="4"
        md="2"
        sm="2"
        >
          <v-select
              v-model="pageSize"
              :items="pageSizes"
              label="Items per page"
          ></v-select>
        </v-col>
        <v-col
            cols="10"
            md="9"
            sm="8"
            align="right"
        >
          <v-pagination
              v-model="currentPage"
              :length="totalPaginationPages"
              total-visible="8"
              circle
          ></v-pagination>
        </v-col>
      </v-row>
    </v-container>

    <DeleteConfirmDialog
        ref="refDeleteConfirmDialog"
        @eventFromDeleteConfirmDialog1="deleteItemConfirmedSingleSelect"
        @eventFromDeleteConfirmDialog2="deleteItemConfirmedMultiSelect"
    ></DeleteConfirmDialog>

    <FMaterialGroup3Dialog :formMode.sync="formMode"
                  ref="refFormDialog"
                  @eventFromFormDialogNew="saveDataNew"
                  @eventFromFormDialogEdit="saveDataEdit"
    ></FMaterialGroup3Dialog>

    <v-snackbar
        v-model="snackbar"
    >
      {{ snackBarMesage }}
      <template v-slot:action="{ attrs }">
        <v-btn
            text
            v-bind="attrs"
            @click="snackbar = false"
        >
          Close
        </v-btn>
      </template>
    </v-snackbar>

  </v-card>
</template>

<script>
import FMaterialGroup3Service from '../../../services/apiservices/f-material-group3-service';
import FMaterialGroup2Service from "../../../services/apiservices/f-material-group2-service";

import DeleteConfirmDialog from "../../utils/DeleteConfirmDialog";

import FMaterialGroup3Dialog from "./FMaterialGroup3Dialog";
import FormMode from "../../../models/form-mode";
import FMaterialGroup3 from '../../../models/f-material-group3'
import FileService from "../../../services/apiservices/file-service";

export default {
  components: { FMaterialGroup3Dialog, DeleteConfirmDialog},
  data () {
    return {
      title: 'GROUP LEV-3',
      snackbar: false,
      snackBarMesage: '',

      multiSelect: false,
      selectedItems: [],

      currentPage: 1,
      totalTablePages: 1,
      totalPaginationPages: 1,
      pageSize: 10,
      pageSizes: [10, 25, 50, 150, 500],

      search: '',
      headers: [
        {
          text: '',
          align: 'center',
          sortable: false,
          value: 'avatar',
        },
        { text: 'Nama Group1', value: 'description', width:"30%" },
        { text: 'Parent Group2', value: 'fmaterialGroup2Bean' },
        { text: 'Actions', value: 'actions', sortable: false },
      ],

      formMode: '',
      itemSelectedIndex: -1,
      itemSelected: '',
      fMaterialGroup3s:[
        new FMaterialGroup3(0, '','')
      ],
      itemsFMaterialGroup2: [],

    }
  },
  watch: {
    currentPage: {
      handler: function (value) {
        if (value) this.fetchFMaterialGroup3()
      }
    },
    pageSize: {
      handler: function (value) {
        const refreshData = (this.currentPage==1)
        this.currentPage =1
        if (refreshData) {
          console.log("Change PageSize " + value)
          this.fetchFMaterialGroup3()
        }
        // console.log("page size = " + value + " >> " + this.totalPages)
      }
    },
  },
  computed: {
    currentUser(){
      return this.$store.state.auth.user;
    },
    fMaterialGroup3sFiltered(){
      return this.fMaterialGroup3s
    }
  },
  methods: {
    searchOnEnter(event){
      if (this.search !== event.target.value) { //Krusial untuk search
        // console.log(`${event.target.value} vs ${this.search}`)
        this.currentPage = 1
        this.search = event.target.value
        this.fetchFMaterialGroup3()
      }
    },
    fetchParent(){
      if (this.currentUser.organizationLevel === "DIV") {
        FMaterialGroup2Service.getFMaterialGroup2ById(this.currentUser.fmaterialGroup2Bean).then(
            response=>{
              this.itemsFMaterialGroup2 = response.data
              // console.log(response.data.items)
            },
            error=>{
              console.log(error.response)
            }
        )
      }else {
        FMaterialGroup2Service.getAllFMaterialGroup2().then(
            response => {
              // console.log(`FMaterial Group2 >> ${JSON.stringify(response.data)}`)
              this.itemsFMaterialGroup2 = response.data
            },
            error => {
              console.log(error.response)
            }
        )
      }

    },
    fetchFMaterialGroup3() {

      FMaterialGroup3Service.getAllFMaterialGroup3Containing(this.currentPage, this.pageSize, "id", "DESC", this.search).then(
          response => {

            // console.log(JSON.stringify(response.data.items))

            const { items, totalPages} = response.data
            this.fMaterialGroup3s = items
            this.totalPaginationPages = totalPages
            console.log(`TotalPage ${totalPages} and TotalItems ${items} `)
          },
          error => {
            console.log(error.response)
            if (error.response.status===401){
              this.snackBarMesage = "Anda tidak mempunyai Akses!!"
              this.snackbar = true
            }
          }
      )

    },
    linkNeated(link){
      return link.replace(/\s+/g, '-').toLocaleLowerCase()
    },

    showDialogNew() {

      this.itemSelectedIndex =-1
      const itemModified = Object.assign({}, '')
      // this.formDialogShow = true
      this.formMode = FormMode.NEW_FORM
      this.$refs.refFormDialog.showDialog(this.itemSelectedIndex, itemModified, this.itemsFMaterialGroup2)
    },
    saveDataNew(itemFromRest){
      this.itemSelected = itemFromRest
      this.closeDialog()
      /**
       * Setiap data baru harus terlihat
       */
      // this.fMaterialGroup3s.push(this.itemSelected)
      // this.fMaterialGroup3s = [itemFromRest].concat(this.fMaterialGroup3s)
      // this.fMaterialGroup3s.unshift(itemFromRest)
      this.fetchFMaterialGroup3()
    },

    showDialogEdit (item) {
      this.itemSelectedIndex = this.fMaterialGroup3sFiltered.indexOf(item)
      const itemModified = Object.assign({}, item)
      // this.formDialogShow = true
      this.formMode = FormMode.EDIT_FORM
      this.$refs.refFormDialog.showDialog(this.itemSelectedIndex, itemModified, this.itemsFMaterialGroup2)
    },
    saveDataEdit(item){
        this.itemSelected = item
        try {
          Object.assign(this.fMaterialGroup3s[this.itemSelectedIndex], this.itemSelected)
        }catch (e) {
            this.fetchFMaterialGroup3()
            e.toString()
        }
        this.closeDialog()
    },


    deleteDialogShow (item) {
      // console.log(item)
      this.itemSelectedIndex = this.fMaterialGroup3sFiltered.indexOf(item)
      this.itemSelected = Object.assign({}, item)
      // this.myConfirmDelete = true
      this.$refs.refDeleteConfirmDialog.showDialog(this.itemSelectedIndex, item.username)
    },
    deleteDialogMultiShow(){
      if (this.multiSelect===true){
        // console.log(`nilai ${this.selectedItems}`)
        this.$refs.refDeleteConfirmDialog.showDialogMulti(this.selectedItems, `${this.selectedItems.length} items selected`)
      }
    },
    deleteItemConfirmedSingleSelect(index){
      console.log("delete SingleItem: " + index)

      const deletedItem = this.fMaterialGroup3s[this.itemSelectedIndex]
      FMaterialGroup3Service.deleteFMaterialGroup3(deletedItem.id).then(
        () => {
          // console.log("hapus bos " + response.data + " >> " + this.itemSelectedIndex)
          this.fMaterialGroup3s.splice(this.itemSelectedIndex, 1)
          this.closeDialog()
        },
        error => {
          console.log(error)
          this.snackBarMesage = 'gagal hapus (digunakan oleh data anakk)'
          this.snackbar = true
          this.$refs.refDeleteConfirmDialog.setDialogState(false)
        }
      )
    },
    deleteItemConfirmedMultiSelect(items){
      // console.log(okeMessage)
      if (items.length >-1){
        let itemIds = []
        for (let i=0; i<items.length; i++){
            itemIds.push(items[i].id)
        }
        // console.log("Item idex: " + itemIds)

        FMaterialGroup3Service.deleteAllFMaterialGroup3(itemIds).then(
            response => {
              if (response.data) {
                  for (let i = 0; i < items.length; i++) {
                    if (! response.data.includes(items[i].id)  || response.data.length === 0) {
                      const index = this.fMaterialGroup3sFiltered.indexOf(items[i])
                      this.fMaterialGroup3s.splice(index, 1)
                    }
                    if (response.data.length > 0) {
                      this.snackBarMesage = `${response.data.length} items gagal hapus (digunakan oleh data anak)`
                      this.snackbar = true
                    }

                    console.log("Undeleted Items: " + response.data + " = " + items[i].id)
                }
                this.closeDialog()
              }
            },
            error => {
              console.log("error " + error)
            }
        )

        // console.log(items)
        // FMaterialGroup3Service.deleteAllFMaterialGroup3s()
      }

    },
    closeDialog () {
      // this.myConfirmDialog = false
      this.formMode = ''
      this.$refs.refDeleteConfirmDialog.setDialogState(false)
      this.$refs.refFormDialog.setDialogState(false)

      this.$nextTick(() => {
        this.itemSelected = Object.assign({}, new FMaterialGroup3())
        this.itemSelectedIndex = -1
      })
    },

    getColorStatusActive (trueFalse) {
      if (trueFalse === true) return 'green'
      else if (trueFalse === false) return 'gray'
      else return 'gray'
    },

    lookupFMaterialGroup2 (fmaterialGroup2Bean) {
      const str = this.itemsFMaterialGroup2.filter(x => x.id===fmaterialGroup2Bean)
      if (str.length>0){
        return str[0].description
      }else {
        return '-'
      }
    },


    lookupImageUrl(item){
      if (item.avatarImage===undefined || item.avatarImage===""){
        return require('@/assets/images/no_image_available.jpeg')
      }else {
        return FileService.image_url_low(item.avatarImage)
      }
    },
    lookupImageUrlLazy(item){
      if (item.avatarImage===undefined || item.avatarImage===""){
        return require('@/assets/images/no_image_available.jpeg')
      }else {
        return FileService.image_url_verylow(item.avatarImage)
      }
    },

  },
  mounted() {
    if (!this.currentUser) {
      this.$router.push('/login')
    }else {
      this.fetchFMaterialGroup3()
      this.fetchParent()

    }
  }

}
</script>

<style scoped>

</style>