export default class FMaterialGroup2 {
  constructor(
      id,
      sourceId,
      kode1,
      kode2,
      description,
      avatarImage,
      fmaterialGroup1Bean,
      statusActive,
      created,
      modified,
      modifiedBy
  ) {
    this.id = id;
    this.sourceId = sourceId;
    this.kode1 = kode1;
    this.kode2 = kode2;
    this.description = description;
    this.avatarImage = avatarImage;
    this.fmaterialGroup1Bean = fmaterialGroup1Bean;
    this.statusActive = statusActive;
    this.created = created;
    this.modified = modified;
    this.modifiedBy = modifiedBy;
  }
}
