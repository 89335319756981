<template>
  <v-card class="mt-2 ml-2 mr-2">
    <v-tabs :vertical="$vuetify.breakpoint.mdAndUp" :centered="$vuetify.breakpoint.smAndDown" color="deep-purple accent-4" background-color="#F5F5FF5F">
      <v-tab  class="justify-md-start" disabled>
        <v-icon left>
          {{ firstTabIcon }}
        </v-icon>
        {{ firstTabTitle }}
      </v-tab>

      <v-tab class="justify-start" @click="tabMaterialGroup1Click">
        GROUP-LEV1
      </v-tab>
      <v-tab class="justify-start" @click="tabMaterialGroup2Click">
        GROUP-LEV2
      </v-tab>
      <v-tab class="justify-start" @click="tabMaterialGroup3Click">
        GROUP-LEV3
      </v-tab>

      <v-tab-item>
        <v-card flat>
          <v-card-text>
            <p>
            </p>
          </v-card-text>
        </v-card>
      </v-tab-item>

      <v-tab-item>
        <v-card flat>
          <v-card-text>
            <FMaterialGroup1Table ref="refTabMaterialGroup1Click"/>
          </v-card-text>
        </v-card>
      </v-tab-item>
      <v-tab-item>
        <v-card flat>
          <v-card-text>
            <FMaterialGroup2Table ref="refTabMaterialGroup2Click"/>
          </v-card-text>
        </v-card>
      </v-tab-item>
      <v-tab-item>
        <v-card flat>
          <v-card-text>
            <FMaterialGroup3Table ref="refTabMaterialGroup3Click"/>
          </v-card-text>
        </v-card>
      </v-tab-item>

    </v-tabs>

  </v-card>
</template>

<script>
import FMaterialGroup1Table from "../../components/product/category/FMaterialGroup1Table";
import FMaterialGroup2Table from "../../components/product/category/FMaterialGroup2Table";
import FMaterialGroup3Table from "../../components/product/category/FMaterialGroup3Table";

export default {
  name: "ProductCateg",
  components: { FMaterialGroup1Table,  FMaterialGroup2Table, FMaterialGroup3Table  },
  data() {
    return {
      firstTabTitle: 'KATEGORI',
      firstTabIcon: 'mdi-file-tree'
    }
  },
  methods: {
    tabUsersClick(){
      // this.$refs.refUsersTable.fetchParent()
    },
    tabMaterialGroup1Click(){
      try {
        this.$refs.refTabMaterialGroup1Click.fetchParent()
      }catch (e) {
        e.toString()
      }
    },
    tabMaterialGroup2Click(){
      try {
        this.$refs.refTabMaterialGroup2Click.fetchParent()
      }catch (e) {
        e.toString()
      }
    },
    tabMaterialGroup3Click(){
      try {
        this.$refs.refTabMaterialGroup3Click.fetchParent()
      }catch (e) {
        e.toString()
      }
    },

  }
}
</script>

<style scoped>
</style>